import React, { useEffect, useState } from "react";
require("react-dom");
import axios from "axios";
const backendUrl = "http://3.135.217.238:5000/api";

const RegisterCandidat = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [courriel, setCourriel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("candidat");

  const handleSubmit = (e) => {
    e.preventDefault();
    register();
  };
  async function register() {
    axios
      .post(`${backendUrl}/utilisateurs/register`, {
        nom: nom,
        prenom: prenom,
        courriel: courriel,
        password: password,
        role: role,
      })
      .catch((err) => {
        console.error("Erreur:", err);
      });

    alert("Compte créé avec succès!");

    setNom("");
    setPrenom("");
    setCourriel("");
    setPassword("");
    setConfirmPassword("");
  }

  return (
    <div className="container">
      <h2>Créer un compte candidat</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom:</label>
          <input
            type="text"
            name="nom"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Prénom:</label>
          <input
            type="text"
            name="prenom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Courriel:</label>
          <input
            type="email"
            name="courriel"
            value={courriel}
            onChange={(e) => setCourriel(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mot de passe:</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Confirmer mot de passe:</label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Créer un compte</button>
      </form>
    </div>
  );
};

export default RegisterCandidat;
