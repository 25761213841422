import React from "react";

const JobOffersList = () => {
  const jobOffers = [
    {
      id: 1,
      title: "Développeur Web",
      description:
        "Développement de sites web\nRecruteur : John Doe\nEmail : johndoe321@gmail.com\nTéléphone : 123-456-7890",
    },
    {
      id: 2,
      title: "Designer Graphique",
      description:
        "Création de designs graphiques\nRecruteur : Jane Smith\nEmail : janesmith123@hotmail.com\nTéléphone : 987-654-3210",
    },
    {
      id: 3,
      title: "Chef de Projet",
      description:
        "Gestion de projets divers\nRecruteur : Bob Johnson\nEmail : bobjohnson543@yahoo.com\nTéléphone : 456-789-0123",
    },
    // Add more job offers as needed
  ];

  return (
    <div className="job-offers-container">
      <h2>Offres d'Emploi</h2>
      <ul>
        {jobOffers.map((offer) => (
          <li key={offer.id}>
            <h3>{offer.title}</h3>
            <p>{offer.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobOffersList;
