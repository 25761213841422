import React, { useEffect, useState } from "react";
import LoginCandidat from "./SessionCandidat/LoginCandidat";
import RegisterCandidat from "./SessionCandidat/RegisterCandidat";
import LoginEnterprise from "./SessionEntreprise/LoginEntreprise";
import RegisterEnterprise from "./SessionEntreprise/RegisterEntreprise";
import JobOffersList from "./JobOffers/JobOffersList";
import "./App.css";


const backendUrl = "http://3.135.217.238:5000/api";
//const backendUrl = "http://localhost:5000/api";

const App = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [userType, setUserType] = useState("candidate");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [users, setUsers] = useState([]);

useEffect(() => {
  fetch(`${backendUrl}/utilisateurs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => setUsers(data))
    .catch((err) => {
      
    });
  }
  , []);
  console.log((users));
    
  const handleToggleUserType = () => {
    setUserType(userType === "candidate" ? "entrepreneur" : "candidate");
    setIsLogin(true);
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="app-container">
      {isAuthenticated ? (
        <JobOffersList />
      ) : userType === "candidate" ? (
        isLogin ? (
          <LoginCandidat onLoginSuccess={handleLoginSuccess} />
        ) : (
          <RegisterCandidat />
        )
      ) : isLogin ? (
        <LoginEnterprise />
      ) : (
        <RegisterEnterprise />
      )}

      {/* Render buttons only if not authenticated */}
      {!isAuthenticated && (
        <>
          <button
            onClick={() => setIsLogin((prev) => !prev)}
            className="toggle-button"
          >
            {isLogin ? "Créer un compte" : "Se connecter"}
          </button>

          <button onClick={handleToggleUserType} className="toggle-button">
            Interface {userType === "candidate" ? "Entreprise" : "Candidat"}
          </button>
        </>
      )}
    </div>
  );
};

export default App;
