import React from "react";

const LoginEnterprise = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
  };

  return (
    <div className="container">
      <h2>Connexion Entreprise</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Courriel:</label>
          <input type="email" required />
        </div>
        <div>
          <label>Mot de passe:</label>
          <input type="password" required />
        </div>
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
};

export default LoginEnterprise;
