import React, { useState } from "react";
import axios from "axios";
const backendUrl = "http://3.135.217.238:5000/api";
const LoginCandidat = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    login();
  };

  // Retrieve existing candidats from localStorage

  // Find a candidat with matching email and password

  async function login() {
    axios
      .post(`${backendUrl}/utilisateurs/login`, {
        courriel: email,
        password: password,
      })
      .then((res) => {
        if (res.status === 201) {
          alert("Connexion réussie!");
          onLoginSuccess();
        }
      })
      .catch((err) => {
        console.error("Erreur:", err);
      });
  }

  return (
    <div className="container">
      <h2>Connexion Candidat</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Courriel:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mot de passe:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
};

export default LoginCandidat;
